<template>
    <v-main>
        <v-container fluid fill-height>
            <v-row align="center" justify="center" class="mx-0">
                <v-col cols="12" sm="6" md="5" lg="4" xl="3">
                    <img
                        v-if="logo"
                        :src="`/domain/${logo}`"
                        @error.once="logoFallback"
                        class="mb-3 d-block"
                        :alt="companyName"
                        style="max-width: 200px; margin: auto"
                    />
                    <login-method :is="loginComponent" :auth-method="authMethod"/>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import BankId from "./methods/BankIdQr";
import Credentials from "./methods/Credentials";
import Criipto from "./methods/Criipto";

export default {
    props: {
        authMethod: {
            required: true,
            type: String,
        },
    },

    components: {
        "method-custom_limetype": Credentials,
        "method-lime_user": Credentials,
        "method-bankid": BankId,
        "method-criipto": Criipto,
    },

    computed: {
        logo() {
            return _.get(window, "portal.logo_filename", null);
        },

        companyName() {
            let companyName = _.get(window, "portal.company_name");
            if (!companyName) {
                return console.error('Missing "porta.company_name" in config.');
            }
            return companyName;
        },

        loginComponent() {
            switch (this.authMethod) {
                case "bankid":
                    return "method-bankid";
                    break;

                case "custom_limetype":
                    return "method-custom_limetype";
                    break;

                case "lime_user":
                    return "method-lime_user";
                    break;
                case "criipto":
                    return "method-criipto";
                    break;
            }
        },
    },

    methods: {
        logoFallback(e) {
            e.target.src = `/storage/${this.logo}`;
        },
    },
};
</script>
