import Bootstrapper from './../bootstrap';

import ResetPassword from './ResetPassword.vue';
import AccountActivation from './AccountActivation.vue';
import LoginForm from './LoginForm.vue';
import LogoutForm from './LogoutForm.vue';
import MultipleLoginForm from './MultipleMethodWrapper.vue';
import Snackbar from './../components/Snackbar';
import BankIdIcon from './../components/Icons/BankId.vue';
import VippsIcon from './../components/Icons/Vipps.vue';
import NemIdIcon from './../components/Icons/NemId.vue';
import NoBankIdIcon from './../components/Icons/NoBankId.vue';
import store from './../store';
import LanguagePicker from './LanguagePicker.vue';
import SelectUserEntity from './SelectUserEntity.vue';
import CookieInformationFooter from './CookieInformationFooter.vue';

let portalConfig = _.get(window, 'portal', {});

const bs = new Bootstrapper(portalConfig);

bs.configureVue(Vue => {

  const vuetifyOptions = {
    icons: {
      values: {
        'bankid': {
          component: BankIdIcon
        },
        'vipps': {
          component: VippsIcon
        },
        'nemid': {
          component: NemIdIcon
        },
        'nobankid': {
          component: NoBankIdIcon
        }
      }
    },
    theme: {
      options: {
        customProperties: true,
      },
      themes: {
        light: {
          background: portalConfig.primary_color
        }
      }
    }
  };

  const vuetify = bs.getVuetifyInstance(vuetifyOptions);
  const i18n = bs.getI18nInstance();

  return new Vue({
    store,

    vuetify,
    i18n,

    components: {
      ResetPassword,
      LoginForm,
      LogoutForm,
      AccountActivation,
      MultipleLoginForm,
      'snackbar': Snackbar,
      LanguagePicker,
      SelectUserEntity,
      CookieInformationFooter,
    }
  }).$mount('#app');

})
bs.exec();
