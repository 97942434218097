var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayInformation
    ? _c(
        "v-footer",
        { attrs: { dark: "", fixed: "" } },
        [
          _c(
            "v-card",
            { staticClass: "text-center", attrs: { width: "100%" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("cookieConsent")) +
                            "\n                "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.acceptCookies(true)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("common.accept")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.acceptCookies(false)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("common.close")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }