<template>
    <v-footer
        v-if="displayInformation"
        dark
        fixed
    >
        <v-card 
            class="text-center"
            width="100%"
        >
            <v-card-text>
                <v-row>
                    <v-col>
                        {{ $t('cookieConsent') }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn 
                            small
                            @click="acceptCookies(true)"
                        >
                            {{ $t('common.accept') }}
                        </v-btn>
                        <v-btn
                            small
                            @click="acceptCookies(false)"
                        >
                            {{ $t('common.close') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
export default {
    data() {
        return {
            displayInformation: false,
            COOKIES_ACCEPTED: '_lime_portal_cookies_accepted',
        }
    },
    mounted() {
        const localStorageValue = localStorage.getItem(this.COOKIES_ACCEPTED);
        this.displayInformation = localStorageValue == null || localStorageValue !== 'true';
    },
    methods: {
        acceptCookies(value) {
            if (! _.isBoolean(value)) {
                throw new Error('Invalid value for acceptCookies');
            }
            localStorage.setItem(this.COOKIES_ACCEPTED, value);
            this.displayInformation = false;
        }
    },
}
</script>
