export default {
    cookieConsent: 'Käytämme evästeitä parantaaksemme kokemustasi verkkosivustollamme. Jatkamalla sivustomme käyttöä hyväksyt evästeiden käytön.',
    common: {
        accept: 'Hyväksy',
        loading: 'Ladataan...',
        somethingWentWrong: 'Jokin meni pieleen!',
        close: 'Sulje',
        submit: 'Lähetä',
        cancel: 'Peruuta',
        ok: 'OK',
        refresh: 'Päivitä',
        yes: 'Kyllä',
        no: 'Ei',
        enter: 'Määritä',
        create: 'Luo'
    },
    limetype: {
        saveChanges: 'Tallenna muutokset',
        noChanges: 'Ei muutoksia',
        updateSuccess: 'Hyvin meni! Kohde päivitettiin',
        requestError: code => `Pyyntö epäonnistui ilmoituksella: ${code}`,
        error: 'Jokin meni pieleen. Kokeile uudestaan ja ole tarvittaessa yhteydessä järjestelmänvalvojaan.',
        filter: 'Suodata',
        noData: 'Ei tietoja'
    },
    auth: {
        login: 'Kirjaudu sisään',
        loginUsingBankId: 'Kirjaudu pankkitunnuksella',
        loginUsingCredentials: 'Kirjaudu tunnuksella',
        loginWith: 'Kirjaudu tunnistuspalvelullla',
        userDetails: 'Käyttäjän tiedot',
        forgotPassword: 'Unohditko salasanasi?',
        forgotPasswordInfo: "Anna sähköpostiosoitteesi, niin lähetämme linkin josta voit päivittää salasanasi.",
        resetLinkSent: 'Resetointilinkki lähetetty sähköpostitse!',
        usernameLabel: 'Käyttäjänimi',
        passwordLabel: 'Salasana',
        loginSuccess: 'Olet nyt kirjautuneena!',
        personalNumberInputLabel: 'YYYYMMDDNNNN',
        personalNumberLabel: 'Henkilötunnus',
        emailLabel: 'Sähköpostiosoite',
        emailInputLabel: 'Kirjoita sähköpostiosoitteesi',
        resetPassword: 'Palauta salasana',
        newPasswordLabel: 'Uusi salasana',
        newPasswordInputLabel: 'Kirjoita uusi salasana',
        confirmPasswordLabel: 'Vahvista uusi salasana',
        resetPasswordSuccess: 'Salasanasi on palautettu. Sinut ohjataan kirjautumissivulle, jossa voit kirjautua uudella salasanalla.',
        "urn:grn:authn:no:bankid": 'Mobiili vai Selain',
        "urn:grn:authn:no:vipps": 'Vipps',
        "urn:grn:authn:no:bankid:central": "NO BankID - Code card",
        "urn:grn:authn:no:bankid:mobile": "NO BankID - Mobile",
        "urn:grn:authn:dk:nemid:poces": "Henkilökohtainen, avainlukulista",
        "urn:grn:authn:dk:nemid:moces": "Työntekijä, avainlukulista",
        "urn:grn:authn:dk:nemid:moces:codefile": "Työntekijä, avainlukutiedosto",
        "urn:grn:authn:se:bankid:another-device": "BankID toisella laitteella",
        "urn:grn:authn:se:bankid:same-device": "BankID tällä laitteella",
        authMethodInputLabel: 'Valitse menetelmä',
        authMethodLabel: 'Tunnistautumistapa',
        phoneNumberLabel: 'Matkapuhelin',
        phoneNumberInputLabel: 'XXXXXXXX',
        activationLinkSent: 'Aktivointisähköposti lähetetty!',
        activateAccount: 'Ota tili käyttöön',
        activateAccountInfo: 'Syötä sähköpostiosoitteesi, niin lähetämme linkin salasananvaihtosivulle.',
        loginAgain: "Kirjaudu uudelleen",
        loggedOut: "Sinut on kirjattu ulos!"
    },
    nav: {
        loggedInAs: 'Kirjautunut nimellä',
        logout: 'Kirjaudu ulos',
        start: 'Aloita',
        index: 'Luettelo',
        create: 'Luo'
    },
    startView: 'Aloitusnäkymä',
    startViewNotConfigured: 'No start view configured. See /readme.txt in the root folder for instructions.',
    pageNotFoundTitle: 'Ups, 404',
    pageNotFoundText: 'Sivua ei löytynyt.',
    languagePicker: {
        da: "Tanskan kieli",
        de: "Saksan kieli",
        en: "Englanti",
        fi: "Suomalainen",
        nl: "Hollannin kieli",
        no: "Norjan kieli",
        sv: "Ruotsin kieli"
    }
}